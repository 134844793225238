.Navigation {
	padding: 20px;
	margin-bottom: 10px;
	ul {
		display: flex;
		flex-flow: row wrap;
		gap: 10px;
		list-style: none;
		margin: 0;
		padding: 0;
	}
	li {
		flex: 0 1 auto;
	}
	li a {
		color: var(--color-black);
		display: block;
		padding: 5px 15px;
		border: 1px solid currentColor;
		text-decoration: none;
		border-radius: 50px;
		&.active,
		&:hover,
		&:focus-visible {
			background-color: var(--color-spot);
			border-color: var(--color-spot);
			color: #FFF;
		}
	}
}