.AddressSingle {
	padding: 20px;
	.content {
		p.name {
			font-size: 1.4rem;
		}
		p.title {
			font-size: 1.3rem;
			font-weight: bold;
			margin: 20px 0;
		}
	}
}