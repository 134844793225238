body {
	background-color: #ccc !important;
}

.App {
	margin: 0 auto;
	background-color: var(--color-bg);
	max-width: 550px;
	min-height: 100vh;
}

.heading {
	font-size: 1.8rem;
	margin: 0 0 20px 0;
	font-weight: normal;
}

.App > .Loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 50px;
	}
}