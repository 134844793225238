.BackToTop {
	position: fixed;
	right: 20px;
	bottom: 20px;
	width: 60px;
	height: 60px;
	appearance: none;
	border-radius: 50%;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-spot);
	color: #FFF;
	animation: backToTopAnimation .5s forwards;
	svg {
		width: 70%;
		height: auto;
		transform: rotate(-90deg);
	}
	span {
		visibility: hidden;
	}
}

@keyframes backToTopAnimation {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}