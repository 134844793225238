.Footer {
	padding: 20px;
	margin-top: 50px;

	a {
		color: inherit;
		text-decoration: none;
	}

	p.copy {
		margin-top: 20px;
		font-size: .9rem;
	}

	p.fagprisen {
		font-size: 1.1rem;
		margin: 40px 0 20px 0;
	}
}