.Addresses {
	padding: 20px;
	min-height: 100vh;
}

.Addresses > .Loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 50px;
	}
}

.Address {
	border: 1px solid currentColor;
	position: relative;
	margin: 120px 0 40px 0;
	padding: 90px 20px 20px 20px;
	background-color: #FFF;
	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		border: 1px solid currentColor;
		position: absolute;
		top: 15px;
		left: 15px;
		z-index: -1;
	}
	img {
		width: 160px;
		height: 160px;
		border-radius: 50%;
		object-fit: cover;
		object-position: center;
		top: -80px;
		position: absolute;
	}
	a {
		text-decoration: none;
		color: inherit;
	}
	p.title {
		font-size: 1.3rem;
	}

	p.name {
		margin-top: 20px;
		font-size: .9rem;
	}
	svg {
		position: absolute;
		right: 20px;
		bottom: 20px;
		color: var(--color-spot);
		width: 30px;
	}
}