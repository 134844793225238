.Jury {
	padding: 20px;
	min-height: 100vh;
}

.Jury > .Loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 50px;
	}
}

.JuryListCategory {
	margin: 0 0 15px 0;
	button {
		appearance: none;
		background: transparent;
		cursor: pointer;
		border: 0;
		padding: 0;
		font-size: 20px;
		border-radius: none;
		width: 100%;
		text-align: left;
		padding-right: 50px;
		position: relative;
		border-bottom: 1px solid currentColor;
		color: var(--color-black);
		span {
			padding-right: 10px;
		}
		.Loader {
			position: absolute;
			display: inline-block;
			width: 25px;
			svg {
				width: 100%;
			}
		}
		svg.arrow {
			width: 25px;
			height: 20px;
			position: absolute;
			right: 0px;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform .3s ease-out;
		}
		&:hover {
			color: var(--color-spot);
		}
	}
	&.active {
		button {
			color: var(--color-spot);
			svg.arrow {
				transform: rotate(90deg);
			}
		}
	}
	.content {
		overflow: hidden;
	}
	.nominee {
		margin: 25px 0;
		.image {
			position: relative;
			aspect-ratio: 16/9;
			margin-bottom: 10px;
			img {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		p.production_company {
			font-size: .9rem;
		}
		p.title {
			font-size: 1.2rem;
		}
	}
	p.juryItem {
		font-size: 1.1rem;
		margin: 5px 0;
	}

}