.Home .Logos {
	padding: 20px;
}

.Logos {
	h2 {
		font-weight: normal;
		font-size: 1.3rem;
	}
	a {
		border: 0;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
		margin-top: 20px;

		li {
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				max-width: 100px;
				max-height: 40px;
			}
		}
	}

	@media(max-width: 400px) {
		ul {
			li img {
				max-width: 70px;
			}
		}
	}
}