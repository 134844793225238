.Ad {
	padding: 10px;
	margin: 30px auto;
	max-width: 300px;
	p {
		font-size: .8rem;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
	img {
		width: 100%;
	}
}