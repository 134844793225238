.HomeIntro {
	height: 45vh;
	position: relative;
	padding: 20px 20px 40px 20px;
	background: #000;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		opacity: .6;
	}

	h1 {
		position: relative;
		z-index: 2;
		color: #FFF;
		text-align: center;
	}
	svg {
		position: absolute;
		z-index: 3;
		top: 30px;
		left: 30px;
		width: 80px;
		height: auto;
	}
	margin-bottom: 10px;
}