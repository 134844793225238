.Committee {
	padding: 20px;
	min-height: 100vh;
}

.Committee > .Loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 50px;
	}
}

.CommitteePeople {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 30px;

	p.title {
		margin-top: 10px;
	}
	p.name {
		font-size: 1.15rem;
	}
}

.CommitteePerson {
	.image {
		position: relative;
		width: 80%;
		overflow: hidden;
		border-radius: 50%;
		aspect-ratio: 1/1;
		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}